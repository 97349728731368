import React, { Component } from 'react';
import '../css/App.css';

import SchrijfBericht from './SchrijfBericht';
import ZoekBericht from './ZoekBericht';
import AlleBerichten from './AlleBerichten';

class App extends Component {

  constructor() {
    super();
    this.state = {
      berichten: [],
      lastIndex: 0
    }
  }

  componentDidMount() {
    fetch('./data.json')
    .then(response => response.json())
    .then(result => {
      const overzicht = result.map(item => {
        item.berichtId = this.state.lastIndex;
        this.setState({ lastIndex: this.state.lastIndex + 1 });
        return item;
      });
      this.setState({
        berichten: overzicht
      });
    });
  }

  render() {
    return (
      <main className="page bg-white" id="petratings">
        <div className="container">
          <div className="row">
            <div className="col-md-12 bg-white">
              <div className="container">
                <SchrijfBericht />
                <ZoekBericht />
                <hr />
                <AlleBerichten groepsberichten={this.state.berichten} />
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default App;

import React, { Component } from 'react';
import { FaTimes } from 'react-icons/fa';
import { FaEdit } from 'react-icons/fa';
import { FaHeart } from 'react-icons/fa';
import { FaComment } from 'react-icons/fa';
import Moment from 'react-moment';

class AlleBerichten extends Component {
	render() {
		return (
			<div>
			{this.props.groepsberichten.map(item => (
				<div key={item.berichtId}>
					<div><p><Moment locale="nl_NL" date={item.datum} fromNow ago /> geleden door {item.auteur}</p></div>		
					<div className="title"><h4>{item.titel}</h4></div>
					<div className="item"><p>{item.bericht}</p></div>
					<div>
						<button type="button" className="btn btn-sm btn-outline-primary"><FaComment /></button>
						<button type="button" className="btn btn-sm btn-outline-primary"><FaHeart /></button>
						<button type="button" className="btn btn-sm btn-outline-primary"><FaEdit /></button>
						<button type="button" className="btn btn-sm btn-outline-danger"><FaTimes /></button>
					</div>
					<hr></hr>
				</div>
			  ))}
			</div>
		);
	}
}

export default AlleBerichten;